@import '../../../../../styles/variables.less';

.apply-searching-by {
  width: 125px;
  height: 25px;

  .ant-select-selection-item {
    background: @primary-color;
    height: 25px;
    border-radius: 17px;
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;
    color: @white-color;
    justify-content: start;
    flex: 0;
    min-width: 125px;
    overflow: none;
    white-space: none;
    text-overflow: none;
    color: @white-color;
    padding-left: 20px !important;
  }

  .ant-select-arrow {
    font-size: 12px;
    font-weight: bold;
    color: @white-color;
    margin-right: 12px;
  }
}

.status-searching {
  width: 100%;
  height: 25px;

  .ant-select-selection-item {
    background: @primary-color;
    height: 25px;
    border-radius: 17px;
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;
    color: @white-color;
    justify-content: start;
    flex: 0;
    min-width: 140px;
    overflow: none;
    white-space: none;
    text-overflow: none;
    color: @white-color;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .ant-select-arrow {
    font-size: 12px;
    font-weight: bold;
    color: @white-color;
    margin-right: 30px;
  }

  .ant-select-clear {
    margin-right: 10px;
    border-radius: 10px;
    color: @white-color;
    background-color: @primary-color;
  }
}

.title-searching {
  font-weight: 800;
  font-size: 16px;
  line-height: 28px;
  padding-left: 6px;
  color: @text-dark;
}

.wrapper-searching {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  height: 100%;
}

.select-items {
  width: 125px;

  .ant-select-selection-item {
    background: @primary-color;
    border-radius: 17px;
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;
    color: @white-color;
    justify-content: center;
    padding: 6px 10px 6px 10px;
    min-width: 125px;
    flex: 0;
    overflow: none;
    white-space: none;
    text-overflow: none;
    color: @white-color;
  }

  .ant-select-arrow {
    padding-left: 150px;
    font-size: 12px;
    font-weight: bold;
    color: @white-color;
  }
}
