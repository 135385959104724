.wrapper-tooltip-to-render-flags {
  display: flex;
  flex: 1;
  justify-content: start;
  align-items: center;
  gap: 8px;
  margin-top: 5px;
  padding: 5px;

  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: black;
  }
}
