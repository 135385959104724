@import '../../../../../../styles/variables.less';

.content-list-companies {
  width: 100%;
  padding: 25px 25px;
  background-color: @secondary-color;
  border: 1px solid @gray-color-3;
  border-radius: 8px;
  flex-direction: column;
  display: flex;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.247) !important;

  .ant-col-24 {
    padding: 0 !important;
    margin: 0 !important;
    max-height: 30px !important;
  }

  .title-list-companies {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: @text-dark;
    margin: 0;
    margin-bottom: 15px;
  }

  .wrapper-list-companies {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .row-list-element {
      width: 100%;
      display: flex;
      align-items: center;
      min-height: 88px;
      background: @white-color;
      border: 1px solid @gray-color-3;
      border-radius: 8px;
      transition: transform 1s;
      cursor: pointer;
      margin: 0;

      &:hover {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      }

      .ant-col {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: @text-dark;
        align-items: center;
        justify-content: flex-start;
        display: flex;
      }
    }
  }

  .status {
    border-radius: 48px;
    line-height: 15.6px;
    padding: 5px 10px;

    &.active {
      background-color: #39c5601f;
      color: #39c560;
    }

    &.inactive {
      background-color: #e21b451f;
      color: #e21b45;
    }
  }

  .sources-found {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    gap: 5px;

    span {
      background-color: #004af71f;
      border-radius: 48px;
      color: #004af7;
      line-height: 15.6px;
      padding: 5px 10px;
    }
  }

  .apply-border-right {
    height: 90px;
    border-right: 1px solid @gray-color-3;
  }

  .apply-border-left {
    height: 90px;
    border-left: 1px solid @gray-color-3;
  }

  .wrapper-company-status {
    display: flex;
    width: 7.1%;
    text-align: center;
    white-space: nowrap;
    flex-wrap: wrap;
  }

  .content-company-status {
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-transform: capitalize;
    flex-direction: row;

    @media (max-width: 1300px) {
      flex-direction: column;
      justify-content: center;
    }
  }

  .align-item-flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 70%;
    height: 100%;

    @media (max-width: 1300px) {
      width: 100%;
      flex-direction: column;
      justify-content: center;
    }
  }

  .apply-border-margin-left-double-6 {
    margin-left: 2.6%;
  }

  .status-column-margin {
    @media (max-width: 1300px) {
      margin-left: 1.555%;
    }
  }

  .apply-border-margin-left-5 {
    margin-left: 1.555%;
  }

  .apply-border-margin-left-6 {
    margin-left: 1.666%;
  }

  .apply-border-margin-left-9 {
    margin-left: 1.999%;
  }

  .showing-items-of-companies {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: @gray-color-1;
    justify-content: space-between;
    display: flex;
    margin-bottom: 40px !important;
    min-height: 40px;

    .ant-col-12 {
      padding: 0;
      margin: 0;
    }

    .left-filters {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      .ant-select-show-search {
        .ant-select-selector {
          .ant-select-selection-search {
            flex: 1;
            padding-right: 22px;

            input {
              cursor: text;
            }

            &::after {
              font-family: 'caf-icons';
              content: '\e834';
              position: absolute;
              right: 0;
              color: #bbb;
              font-size: 17px;
              bottom: 0;
              line-height: 1;
            }
          }
        }
      }

      .style-placeholder-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        width: 275px;
        opacity: 1;
        color: #828282;

        .ant-select-selector {
          background: transparent;

          span,
          input::placeholder {
            color: #828282;
            font-weight: 400;
            line-height: 18px;
            font-size: 14px;
            opacity: 1;
          }
        }
      }

      .style-placeholder-select {
        margin-left: 15px;

        .ant-select-selector {
          background: transparent;

          span,
          input::placeholder {
            color: @text-dark;
            font-weight: 700;
            font-size: 16px;
            opacity: 1;
          }
        }
      }
    }
  }

  .columns-name-companies-list {
    display: flex;
    width: 100%;
    margin-bottom: 15px;

    .ant-col {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
      color: @text-dark;
      white-space: nowrap;
    }
  }

  .company-status-default {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    margin-right: 8px;
    border: 2px solid #e21b45;
    background-color: #e21b45;

    @media (max-width: 1300px) {
      margin-right: 0px;
      margin-bottom: 5px;
    }
  }

  .company-status-active {
    border: 2px solid #39c560;
    background-color: #39c560;
  }

  .company-status-inactive {
    border: 2px solid #e21b45;
    background-color: #e21b45;
  }

  .pagination {
    margin-top: 20px;
    .ant-pagination {
      justify-content: center;
    }
  }
}
