@import '../../../../../../styles/variables.less';

.wrapper-status-button {
  width: 100%;
  height: 100%;
  display: flex;
}

.wrapper-rules-failed {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;

  font-size: 18px;
  line-height: 21px;
  margin-top: 1.25rem;

  span {
    color: #323232;
    font-weight: 700;
    margin-right: 30px;
  }

  strong {
    font-size: 16px;
    font-weight: 700;
    color: white;
    background-color: rgb(248, 189, 25);
    padding: 5px;
    padding-right: 8px;
    padding-left: 8px;
    border-radius: 9px;

    i {
      margin-right: 10px;
    }
  }
}

.class-diver {
  .ant-divider-horizontal {
    margin: 10px 0px !important;
  }
}

.form-reasons {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
}

.button-status {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 9px;
  padding-bottom: 9px;
  border-radius: 7px;
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  color: white;

  cursor: pointer;

  img {
    margin-right: 12px;
  }
}

.button-status-approve {
  background: #39c560;
  margin-right: 15px;
}

.button-status-reprove {
  background: #e21b45;
  margin-left: 15px;
}

.button-status-selected {
  border: 2px solid #004af7;
}

.text-area-input {
  color: '#828282';
  background: '#E0E0E0';
}

.title-action-required {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: end;

  font-size: 16px;
  line-height: 20px;

  h3 {
    margin-bottom: -10px;
  }

  img {
    margin-right: 15px;
  }
}

.content-action-required {
  width: 100%;
  height: 80px;
  padding-left: 45px;
}
