@import '../../../../../styles/variables.less';

.KYC-compliance-padding {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 12px !important;
  }
}

.KYC-compliance-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 30px;
  padding-bottom: 12px;
  justify-content: space-between;

  span:first-child {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: @text-dark;
    white-space: nowrap;
  }

  hr {
    margin-right: 10px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.select-all-results {
  white-space: nowrap;
  margin-right: 10px;

  button {
    cursor: pointer;
  }

  input {
    cursor: pointer;
  }

  span {
    padding-right: 0 !important;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: @text-dark;
  }
}

.filter-matching-type {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-items: end;

  .ant-select-selector {
    background: transparent !important;

    span,
    input::placeholder {
      color: @text-dark !important;
      font-weight: 700 !important;
      font-size: 16px !important;
      opacity: 1 !important;
    }
  }
}

.content-card-overflow {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;

  /* Track */
  &::-webkit-scrollbar-track {
    background: #d9d9d9;
    border-radius: 0px 6px 6px 6px;
    height: 100px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #9b9b9b;
    border-radius: 0px 6px 6px 6px;
    height: 100px;
  }
}
