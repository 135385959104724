.wrapper-card-KYC {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  min-height: 220px;
  height: auto;
  background: #f9f9f9;
  padding: 25px 0px 0px 0px;
}

.applying-opacity {
  opacity: 0.4;
}

.header-infos {
  padding-left: 25px;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;

  strong {
    font-weight: 700;
    font-size: 26px;
    color: #323232;
    margin-left: 0.7rem;
    margin-right: 0.7rem;
  }
}

.results {
  margin-top: 5px;
  padding: 2px 12px;
  display: flex;
  align-items: center;
  color: #17521b;
  font-weight: 400;
  font-size: 13px;
  background: #2d994b29;
  border-radius: 11px;
  height: 22px;

  img {
    width: 9px;
    height: 7px;
    margin-right: 5px;
  }
}
.infos {
  padding-left: 25px;
  padding-top: 25px;
  padding-right: 10px;
  margin-bottom: 0px;
  .ant-row {
    margin-bottom: 20px;
  }
}
.title-categories {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #323232;
}
.text-return {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #323232;
}
.tag-background {
  font-weight: 700 !important;
  font-size: 13px;
  background: #fadae1;
  color: #521717;
  padding: 6px 12px;
  border-radius: 15px;
  margin-right: 5px;
}

.wrapper-check-buttons {
  display: flex;
  align-items: center;
  align-self: end;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 15px 18px 20px;
  background: #d1d1d1;
  button {
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-align: left;
    display: flex;
    font-weight: 700;
    font-size: 18px;
    align-items: center;
    color: #004af7;
    cursor: pointer;
    align-self: end;

    img {
      width: 14px;
      height: 14px;
      margin-right: 5px;
      margin-top: -2px;
    }
  }

  input {
    cursor: pointer;
    background: white;
  }

  .ant-checkbox {
    border-radius: 8px;
    margin-right: 5px !important;
    background-color: white;
  }
}

.radio-group-display {
  display: flex;
  width: 50%;
  align-items: end;
  justify-items: end;
  justify-content: end;
  padding: 10px 0 0 0;
}

.true-match-class {
  margin-right: 20px;
  color: #0baa43;
  font-weight: 700;
  font-size: 16px;
  line-height: 16.41px;

  .ant-checkbox + span {
    padding: 0 !important;
  }

  .ant-checkbox-wrapper:hover {
    border-color: #0baa43 !important;
  }

  .ant-checkbox:hover {
    border-color: #0baa43;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0baa43;
    border-color: #0baa43;
  }

  .ant-checkbox-inner:hover {
    background-color: #0baa43;
    border-color: #0baa43;
  }

  .ant-checkbox-checked:hover {
    background-color: #0baa43;
    border-color: #0baa43;
  }
}

.false-match-class {
  color: #e21b45;
  font-weight: 700;
  font-size: 16px;
  line-height: 16.41px;

  .ant-checkbox + span {
    padding: 0 !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #e21b45;
    border-color: #e21b45;
  }
}

.cursor-pointer {
  cursor: pointer;
}
